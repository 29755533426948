@import '../../scss/imports.scss';

.player-container {
    width: fit-content !important;
}

.zl-session {
    padding: 16px 0;
    border-top: 1px solid $white-border;

    @include media('>=md') {
        padding: 0;
        margin: 16px 0;
        border: 1px solid $white-border;
    }

    & .session-image-container {
        display: block;
        flex-direction: unset;
    }

    & .session-image {
        position: relative;

        @include media('>=md') {
            max-width: unset;
            max-height: 280px;
        }

        & > img {
            width: 100%;
            height: auto;
            object-fit: cover;

            &.no-image {
                position: absolute;
                width: 200px;
                height: 50px;
                left: calc(50% - 100px);
                top: 110px;
                opacity: 0.25;
                object-fit: contain;
            }
        }
    }

    & .play-icon {
        transition: all 0.3s ease;
        cursor: pointer;
        position: absolute;
        opacity: 1;
        left: calc(50% - 26px);
        right: calc(50% - 26px);
        top: calc(50% - 26px);
        bottom: calc(50% - 26px);
        width: 50px;
        height: 50px;
        z-index: 2;

        @include media('>md') {
            left: calc(50% - 20px);
            right: calc(50% - 20px);
            top: calc(50% - 20px);
            bottom: calc(50% - 20px);
            width: 40px;
            height: 40px;
        }

        & > svg {
            width: 40px;
            height: 40px;
            object-fit: cover;

            @include media('>=sm') {
                width: 52px;
                height: 52px;
            }
        }
    }

    & .session-header {
        position: relative;
        background-color: $zl-deep-blue-light;
        overflow: hidden;

        & .package-title {
            font-family: 'SuperGround', sans-serif;
            text-transform: uppercase;
            position: relative;
            display: block;
            font-weight: 500;
            z-index: 2;
            color: $off-white;
            text-shadow: 0 1px 0 #141414;
            letter-spacing: 0.2px;
            line-height: 1;
            font-size: 32px;
            max-width: 348px;
            margin: 0;

            @include media('<md') {
                font-size: 24px;
            }
        }

        & .package-subtitle {
            text-transform: uppercase;
            font-size: 9px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: 0.1px;
            color: $off-white;
            text-shadow: 0 1px 0 #141414;
        }

        & .session-header-container {
            position: absolute;
            bottom: 24px;
            left: 24px;
            z-index: 2;

            @include media('<md') {
                bottom: 18px;
                left: 18px;
            }
        }

        & > img {
            position: absolute;
            width: 100%;
            min-height: 280px;
            object-fit: cover;
        }
    }

    & .add-button-container {
        text-align: right;

        & > .add-button {
            height: 48px;
            min-width: 120px;
            text-transform: uppercase;

            svg path:not([fill='none']) {
                fill: $grey-light;
            }

            &.selected {
                svg path:not([fill='none']) {
                    fill: $success;
                }
            }
        }
    }

    & .package-toggle-container {
        & .ant-btn {
            & > span {
                font-weight: normal;
                color: $zl-light-blue;
                font-size: 14px;
            }
        }

        @include media('>=md') {
            text-align: right;
        }

        & .anticon.caret {
            margin-top: 2px;
            margin-left: 4px;
            font-size: 12px;
            transition: all 0.3s ease-in-out;
            transition-timing-function: cubic-bezier(0.3, 0, 0.7, 1);

            &.active {
                transform: rotate(180deg);
                margin-top: 0px;
            }
        }
    }

    & .package-details {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        & > .one-detail-info {
            margin: 0;
            display: block;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
        }

        & > .two-details-info {
            margin: 0;
            display: block;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0;
        }

        & > .three-details {
            margin: 0;

            @include media('<md') {
                display: block;
                -ms-flex: 0 0 33.33333333%;
                flex: 0 0 33.33333333%;
                max-width: 33.33333333%;
                margin: 0;
            }

            @include media('>=md') {
                margin: 0 35px 0 0;
            }
        }

        & > .three-details-info {
            margin: 0;
            display: block;
            -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
            margin: 0;
        }

        & > .four-details {
            margin: 0;

            @include media('<md') {
                display: block;
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
                margin: 0;
            }

            @include media('>=md') {
                margin: 0 25px 0 0;
            }
        }

        & > .four-details-info {
            margin: 0;
            display: block;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            margin: 0;
        }

        & > .package-detail {
            text-align: center;
            width: auto;
            overflow: visible;
            text-transform: uppercase;

            & > .label {
                color: $grey-dark;
                display: block;
                font-size: 9px;
                line-height: 1.78;
                letter-spacing: 0.1px;

                @include media('>=sm') {
                    font-size: 12px;
                }
            }

            & > .value {
                color: $zl-deep-blue-light;
                font-size: 16px;
                display: block;
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: 0.2px;

                & .time-suffix {
                    font-size: 9px;
                }
            }
        }
    }

    & .package-desc {
        padding: 0;

        @include media('>=md') {
            padding: 0 24px;
        }
    }

    & .mobile-header-row {
        min-height: 120px;
        height: auto;

        & .mobile-session-details {
            min-height: 120px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    & .desktop-details-row {
        padding: 16px 24px;
    }

    & .mobile-details-row {
        padding: 8px 0;
    }
}

.ant-row-rtl {
    .zl-session {
        .session-image {
            text-align: left;
        }

        .add-button-container {
            text-align: left;
        }

        .session-header-container {
            left: unset;
            right: 24px;
        }
    }
}

.ant-typography.upgrade-text {
    color: $grey-dark;
    font-weight: 600;
    padding-inline-start: 4px;
    padding-inline-end: 4px;
    height: 20px;
}

.ant-typography.upgrade-text-small {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
}

.ant-typography.scarcity-text {
    color: #f75c2f;
    font-weight: 600;
    padding-inline-start: 4px;
    padding-inline-end: 4px;
    height: 20px;
}

.scarcity-image {
    height: 16px;
    bottom: 2px;
    position: relative;
    padding-right: 2px;
}

.package-scarcity {
    padding: 0px;

    @include media('>=md') {
        padding: 0 24px;
        position: relative;
        bottom: 8px;
    }
}

.upgrade-holder {
    padding: 0px;
    padding: 8px 24px 0 24px;

    @include media('>=md') {
        padding: 0 24px 10px 24px;
    }
}
