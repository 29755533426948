@import '../../scss/imports.scss';

.booking-alert .ant-row {
    padding: 0px;
    margin: 0px;
}

.booking-alert .ant-typography a {
    color: black;
    text-decoration: underline;
}

.booking-alert .ant-typography a:visited {
    color: black;
    text-decoration: underline;
}

.booking-alert .ant-typography a:hover {
    color: black;
    text-decoration: underline;
}

.player-cutout {
    height: 115px;
    position: absolute;
    bottom: 0px;

    @include media('<sm') {
        display: none;
    }

    @include media('>=sm') {
        left: 400px;
    }

    @include media('>=md') {
        left: 554px;
    }

    @include media('>lg') {
        left: 574px;
    }
}

.player-cutout-rtl {
    height: 115px;
    position: absolute;
    bottom: 0px;

    @include media('<sm') {
        display: none;
    }

    @include media('>=sm') {
        right: 400px;
    }

    @include media('>=md') {
        right: 580px;
    }

    @include media('>lg') {
        right: 580px;
    }
}
