@import '../../scss/imports';

.zl-nav-item-container {
    max-width: 1200px;
    width: 100%;
    margin: 0;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    list-style-type: none;

    @include media('>=md') {
        padding: 22px 40px 22px 120px;
    }

    .zl-nav-item {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        text-transform: uppercase;
        letter-spacing: 0.2px;

        &.incomplete:not(.current) {
            span {
                color: $grey-dark;
            }
        }

        &.incomplete {
            & .divider {
                & svg path[fill='#072F3D'] {
                    fill: $grey-dark;
                }

                & svg path[fill='#072f3d'] {
                    fill: $grey-dark;
                }
            }
        }

        & span {
            margin: 0 2px;
            @include media('>=md') {
                margin: 0 4px;
            }
        }

        & .zl-icon {
            top: 3px;

            & > svg {
                height: 18px;
                width: 18px;
            }
        }

        &:last-child {
            & .zl-icon.divider {
                display: none;
            }
        }
    }
}
