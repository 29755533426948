.zl-alert {
    &.ant-alert-with-description {
        & .ant-alert-icon {
            font-size: 24px;
            top: calc(50% - 12px);
        }

        & .zl-icon {
            & svg path[fill='#38BCEE'] {
                fill: $success;
            }
        }
    }
}
