@import '../../scss/imports.scss';

.summary-container {
    & span.ant-typography {
        color: $zl-deep-blue-light;
        font-size: 14px;
    }

    & strong {
        color: $zl-deep-blue;
    }

    & h2 {
        color: $zl-deep-blue-light;
    }

    & .total {
        border-bottom: 1px solid $grey-lightest;
    }

    & .currency {
        text-align: right;
    }

    & .subtotal {
        padding: 2px 0;
        margin: 5px 0;

        & .subtotal-item:first-child {
            padding-top: 8px;
            border-top: 1px solid $grey-lightest;
        }

        & .subtotal-item:last-child {
            padding-bottom: 8px;
            border-bottom: 1px solid $grey-lightest;
        }
    }

    .total {
        padding: 8px 0 16px 0;
        margin: 5px 0;
        border-bottom: 1px solid $grey-lightest;

        & .payable-total {
            span.ant-typography,
            strong {
                font-weight: 600;
            }
        }

        & .includesTaxes {
            color: $grey-dark;
            font-size: 12px;
            font-weight: 500;
        }
    }

    & .checkout {
        margin-top: 12px;

        & > span.ant-typography {
            font-size: 18px;
            line-height: 16px;
            color: $true-white;
            text-transform: uppercase;
        }
    }

    .modify-button {
        & span.ant-typography {
            color: $zl-light-blue;
            font-size: 14px;
        }

        & .zl-icon > svg {
            width: 18px;
            height: 18px;
        }
    }

    &.drawer {
        background-color: $zl-deep-blue-light;
        transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
        transition-property: opacity 0.1s ease;
        padding: 8px;
        pointer-events: none;
        margin-top: 100px;
        opacity: 0;

        &.show {
            opacity: 1;
            margin-top: 0;
            padding: 8px;
            pointer-events: unset;

            @include media('>=lg') {
                display: none;
            }
        }

        & span.ant-typography {
            color: $off-white;
        }

        & strong {
            color: $true-white;
        }

        & h2 {
            color: $off-white;
        }

        @include media('>=lg') {
            display: none;
        }

        & .checkout {
            margin: 0;
        }

        & .drawer-toggle {
            padding: 12px 0;
            text-align: center;

            & > button {
                &:hover {
                    color: $zl-light-blue;
                }

                & > span.ant-typography {
                    color: $zl-light-blue;
                    text-transform: uppercase;
                }
            }

            .anticon {
                transition: all 0.3s ease-in-out;
                transition-timing-function: cubic-bezier(0.3, 0, 0.7, 1);

                &.active {
                    transform: rotate(180deg);
                }

                &:hover {
                    color: $zl-light-blue;
                }
            }
        }

        & .subtotal {
            & .subtotal-item:first-child {
                border-top: 1px solid rgba($zl-light-blue, 0.32);
            }

            & .subtotal-item:last-child {
                padding-bottom: 8px;
                border-bottom: 1px solid rgba($zl-light-blue, 0.32);
            }
        }

        .total {
            border-bottom: 1px solid rgba($zl-light-blue, 0.32);

            & .includesTaxes {
                color: $off-white;
            }
        }
    }

    &.affixed {
        position: absolute;
        width: calc(100% - 16px);
        top: 50px;
        opacity: 0;
        pointer-events: none;
        background-color: $zl-deep-blue-light;
        margin: 0px 8px 8px 8px;
        padding: 32px;
        transition: opacity 400ms ease, top 400ms ease-in-out;

        &.show {
            top: 0;
            opacity: 1;
            pointer-events: auto;
            max-height: 100vh;
        }

        & h2 {
            font-size: 16px;
            text-transform: uppercase;
        }

        & strong {
            color: $true-white;
        }

        & span.ant-typography {
            color: $off-white;
            font-size: 12px;
        }

        & h2 {
            color: $off-white;
            text-transform: uppercase;
        }

        & .checkout {
            & > span.ant-typography {
                font-size: 18px;
            }
        }

        & .modify {
            .modify-button {
                & span {
                    color: $zl-light-blue;
                }
                & .zl-icon {
                    & > svg path:not([fill='none']) {
                        fill: $true-white;
                    }
                }
            }
        }

        & .summary-items {
            max-height: 70vh;
            overflow: auto;
        }

        & .subtotal {
            & .subtotal-item:first-child {
                border-top: 1px solid rgba($zl-light-blue, 0.32);
            }

            & .subtotal-item:last-child {
                border-bottom: 1px solid rgba($zl-light-blue, 0.32);
            }
        }

        .total {
            border-bottom: 1px solid rgba($zl-light-blue, 0.32);

            & .includesTaxes {
                color: $off-white;
            }
        }
    }
}

.ant-row-rtl {
    & .modify {
        text-align: left;
    }

    & .currency {
        text-align: left;
    }
}
