@import '../../scss/imports.scss';

.zl-recipient-selector-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-left: -4px;
    margin-right: -4px;

    & .zl-recipient-selector-button {
        margin: 0px 4px 60px 4px;
        width: calc((100% - 16px) / 2);
        height: fit-content;
    }
}
