@import '../../scss/imports';

h1, /* Style Guide H5 */
h1.ant-typography {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $zl-deep-blue-lighter;
}

h2,  /* Style Guide H6 */
h2.ant-typography {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.2px;
    color: $zl-deep-blue-light;
    margin-bottom: 24px;
}

h3,  /* Style Guide Subtitle 1 */
h3.ant-typography {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: $zl-deep-blue-light;
}

h4, /* Style Guide Subtitle 2 */
h4.ant-typography {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: $true-white;
}

h4.time-heading {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: $grey-dark;
}

h5, /* Style Guide Overlay */
h5.ant-typography {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: $grey-dark;
}

h6, /* Style Guide Caption */
h6.ant-typography,
caption {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: $zl-deep-blue-light;
}
