@import '../../scss/imports.scss';

.zl-voucher-amount-picker-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-left: -8px;
    margin-right: -8px;

    & .zl-voucher-amount-button {
        margin: 8px 8px;
        width: calc((100% - 32px) / 2);
        height: fit-content;

        @include media('>=sm') {
            width: calc((100% - 64px) / 4);
            height: fit-content;
        }

        .zl-discount-amount {
            font-size: 10px;
            color: white;
            display: inline-block;
            background-color: #ed6000;
            padding: 2px 4px;
            position: relative;
            bottom: 2px;
            margin: 4px;
        }

        .zl-discounted-value {
            font-size: 10px;
        }
    }
}
