@import '../../scss/imports.scss';

.zl-hint {
    padding: 8px;
    margin: 16px 0 0 0;
    border-left: 4px solid $zl-light-blue;
    transition: all 0.3s ease-in-out;

    @include media('>=md') {
        padding: 8px 16px;
    }

    .zl-hint-content,
    .ant-typography {
        color: $grey-dark;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
    }

    &.zl-hint-no-collapse {
        display: flex;
        justify-items: start;
        align-items: center;
        flex-direction: row;

        & .hint-icon:empty {
            display: none;
        }

        & .hint-icon > svg {
            height: 100%;
            min-width: 32px;
            width: 32px;
            margin: 8px 8px 8px 0;
            object-fit: cover;
        }
    }

    .ant-btn {
        padding: 0;
        height: auto;

        & > span {
            color: $zl-light-blue;
            font-weight: 500;
            text-align: left;
            white-space: pre-wrap;
        }
    }

    &:not(.zl-hint-no-collapse) {
        &:hover {
            border-left: 4px solid $zl-deep-blue;

            .hint-toggle {
                transition: all 0.3s ease-in-out;
                transition-timing-function: cubic-bezier(0.3, 0, 0.7, 1);

                & > span {
                    color: $zl-deep-blue;
                }
            }
        }

        .hint-caret {
            height: 1em;
            font-size: 12px;
            position: relative;
            transition: all 0.3s ease-in-out;
            transition-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
            transform: rotate(0deg);

            &.active {
                transform: rotate(180deg);
            }
        }
    }
}

.ant-row-rtl {
    .zl-hint {
        border-left: none;
        border-right: 4px solid $zl-light-blue;

        &:hover {
            border-left: none;
            border-right: 4px solid $zl-deep-blue;
        }
    }

    .zl-hint-no-collapse {
        border-left: none;
        border-right: 4px solid $zl-light-blue;
    }
}
