@import '../../scss/imports.scss';

.zl-section {
    padding: 32px 0;
    background-color: $true-white;
    border: 1px solid $white-border;
    margin-bottom: 8px;
    transition: all 0.3s;

    &.no-padding {
        padding: 0;
    }

    &.black {
        background-color: black;
    }

    &.suffixed.active {
        padding-bottom: 0;
    }

    &.booking-alert {
        background-color: $warning;
    }

    @include media('>=md') {
        margin: 0px 8px 8px 8px;
    }

    @include media('>=lg') {
        margin: 0px 8px 16px 30px;
    }

    .section-title {
        padding: 0 8px;

        @include media('>=md') {
            padding: 0 96px;
        }
    }

    .section-action {
        padding: 0 8px;
        text-align: right;

        @include media('>=md') {
            padding: 0 96px;
        }
    }

    .section-container {
        opacity: 1;
        animation-name: fade-in;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        animation-timing-function: ease-in;
        padding: 0 8px;

        @include media('>=md') {
            padding: 0 96px;
        }

        &.no-padding {
            padding: 0;
        }
    }

    .section-suffix {
        border-top: 1px solid $white-border;
        background-color: $true-white;
        padding: 16px 8px;
        margin-top: 16px;

        @include media('>=md') {
            padding: 16px 96px;
        }

        &:empty {
            display: none;
        }
    }

    &:not(.active) {
        .section-title {
            color: $grey-light;
            margin: 0;
        }

        .section-container,
        .section-suffix {
            opacity: 0;
            display: none;
        }
    }
}
