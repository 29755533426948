$zl-light-blue: #38bcee;
$zl-deep-blue: #020d11;
$zl-deep-blue-light: #051f29;
$zl-deep-blue-lighter: #072f3d;
$zl-text-grey: #6f6f6e;

$true-black: #000000;
$true-white: #ffffff;
$transparent: transparent;

$grey-dark: #70787a;
$grey-light: #a9b4b8;
$grey-lightest: #e5e5e5;
$white-border: #ededed;

$error: #f75c2f;
$warning: #fccd3d;
$success: #1fe073;

$off-white: #ebfaff;
$white-75: rgba(255, 255, 255, 0.75);
$white-50: rgba(255, 255, 255, 0.5);
$white-25: rgba(255, 255, 255, 0.25);

$base-text: $zl-deep-blue-light;
$base-borders: $grey-lightest;
$background: #f5f5f5;
