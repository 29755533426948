@import '../../scss/imports.scss';

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 8px;
    height: 64px;

    @include media('>=md') {
        line-height: 0px;
        padding: 0 32px;

        & > .logo > img {
            line-height: 0px;
        }
    }

    & > .logo > img {
        width: 100%;
        max-width: 120px;
        height: auto;
    }
}

.nav-container {
    padding: 0;
    max-width: unset;
    background-color: $zl-deep-blue-light;
}
