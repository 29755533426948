@import '../../scss/imports';

.zl-loader {
    display: flex;
    vertical-align: middle;
    text-align: center;
    height: 100vh;
    width: 100%;
    background-color: $zl-deep-blue-light;

    & > img {
        width: 200px;
        height: 51px;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        animation: pulse 2s infinite;
    }
}
