@import '../../scss/imports.scss';

.zl-purchase-type-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: -4px;
    margin-right: -4px;
    align-content: center;
    align-items: center;

    & .zl-purchase-type-button {
        align-items: center;
        justify-content: center;
        margin: 8px 4px;
        width: calc((100% - 24px) / 3);
        height: 75px;
        word-break: break-word;
        white-space: normal;
        vertical-align: middle;

        .zl-discount-text {
            display: block;
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 20px;
            color: white;
            background-color: #ed6000;
            padding: 2px;
            font-size: 12px;
        }
    }

    @media (max-width: 575px) {
        .zl-purchase-type-button {
            width: 100%;
            margin: 8px 0;
        }
    }
}
