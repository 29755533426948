$montserrat: 'Montserrat', sans-serif;
$exo2: 'Exo 2', sans-serif;

.ant-typography,
.ant-alert-with-description {
    color: $zl-deep-blue;

    & strong {
        font-weight: 500;
    }

    & a {
        color: $zl-light-blue;
        & span.ant-typography {
            color: $zl-light-blue;
        }

        &:focus {
            color: $zl-light-blue;
        }
        &:visited {
            color: $zl-light-blue;
        }
        &:active {
            color: $zl-deep-blue;
        }
        &:hover {
            color: $zl-deep-blue;
        }
    }
}

i.zl-logo-spinner {
    color: $zl-light-blue !important;
    width: 70px;
    height: 50px;
    margin: -36px !important;
    margin-top: -46px !important;
    animation: pulse 2s infinite;
}

.ant-spin-text {
    color: $zl-light-blue;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid $base-borders;
    -webkit-text-fill-color: $zl-deep-blue-lighter;
    box-shadow: 0 0 0px 1000px $true-white inset;
    -webkit-box-shadow: 0 0 0px 1000px $true-white inset;
    background-color: $true-white;
    transition: background-color 5000s ease-in-out 0s;
}
