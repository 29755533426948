@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes slide-in-br {
    0% {
        -webkit-transform: translateY(16px) translateX(0);
        transform: translateY(16px) translateX(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-br {
    0% {
        -webkit-transform: translateY(16px) translateX(0);
        transform: translateY(16px) translateX(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        display: none;
    }
    100% {
        opacity: 1;
        display: block;
    }
}
