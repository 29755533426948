.ant-collapse.zl-hidden-collapse {
    background: none;

    & > .ant-collapse-item {
        border: none;
    }

    .ant-collapse-header {
        display: none;
    }

    & .ant-collapse-content-box {
        padding: 1px;
    }
}
