.zl-form-item {
    position: relative;

    &.ant-form-item {
        margin-bottom: unset;
    }

    & .ant-input-affix-wrapper:hover,
    & .ant-input:hover,
    & .ant-input-number:hover,
    & textarea:-webkit-autofill:hover,
    & select:-webkit-autofill:hover {
        border-color: $grey-light;
        box-shadow: 0 0 1px 1px $grey-light;
        -webkit-text-fill-color: initial;
        background-color: $true-white;
        color: $zl-deep-blue-lighter;
    }

    & .ant-input-affix-wrapper:focus-within,
    & .ant-input:focus,
    & .ant-input-number:focus,
    & textarea:-webkit-autofill:focus,
    & select:-webkit-autofill:focus {
        border-color: $grey-dark;
        box-shadow: 0 0 1px 1px $grey-dark;
        -webkit-text-fill-color: initial;
        background-color: $true-white;
        color: $zl-deep-blue-lighter;
    }

    & .ant-input-affix-wrapper {
        padding: 1px 0;

        .ant-input,
        .ant-input-number {
            border: none;
            box-shadow: none;
        }
    }

    &.warning {
        & .ant-form-item-label {
            & label {
                color: $warning;
            }
        }
    }

    &.error {
        & .ant-form-item-label {
            & label {
                color: $error;
            }
        }
    }

    & .ant-form-item-label {
        position: absolute;
        opacity: 0;
        background-color: $true-white;
        line-height: 10px;
        margin-left: 8px;
        z-index: 2;
        top: -8px;
        pointer-events: none;
        padding: 2px 0px 2px 8px;
        width: fit-content;

        & .ant-form-item-required::before {
            display: none;
        }

        @include media('<md') {
            padding-right: 8px;
        }

        & label {
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.2px;
            color: $zl-deep-blue-lighter;
            text-transform: uppercase;
            height: auto;
        }
    }

    &.show-label {
        .ant-form-item-label {
            display: inline-block;
            -webkit-animation: slide-in-br 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) normal forwards;
            animation: slide-in-br 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) normal forwards;
        }
    }

    & .ant-form-item-control {
        width: 100%;

        & .ant-input-number,
        & .ant-input {
            font-size: 16px;
            background-color: $true-white;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.2px;
            color: $zl-deep-blue-lighter;
            padding: 16px !important;
            border-radius: 2px;
        }

        & .ant-input-prefix {
            margin: 0px 0px 0px 16px;
        }

        & .ant-form-explain {
            margin: 8px 16px;
            font-size: 14px;
        }

        &.has-error {
            & .ant-form-explain {
                color: $error;
            }

            & input.zl-input,
            & input-number.zl-input {
                color: $error;

                &:focus {
                    border-color: $error;
                    box-shadow: 0 0 1px 1px $error;
                }
            }
        }

        &.has-warning {
            & .ant-form-explain {
                color: $warning;
            }

            & input.zl-input {
                color: $warning;

                &:focus {
                    border-color: $warning;
                    box-shadow: 0 0 1px 1px $warning;
                }
            }
        }

        & .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
        }
    }

    &:focus-within {
        .ant-form-item-label {
            display: inline-block;
            -webkit-animation: slide-in-br 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) normal forwards;
            animation: slide-in-br 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) normal forwards;
        }

        & .ant-input-group-addon {
            border-color: $grey-dark;
            box-shadow: 0 0 1px 1px $grey-dark;

            & + .ant-input,
            & + .ant-input-number {
                border-color: $grey-dark;
                box-shadow: 0 0 1px 1px $grey-dark;
            }
        }
    }

    & .ant-input-group-addon {
        background-color: $true-white;
        border-radius: 2px;
        padding: 0;

        & .ant-select-selection-selected-value {
            margin-top: 8px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.2px;
            font-size: 16px;
            color: $zl-deep-blue-lighter;
        }
    }
}

//sorry for putting this here :(
.phone-dropdown {
    width: 25% !important;

    @include media('<=sm') {
        width: 75% !important;
    }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $grey-dark;
    -webkit-box-shadow: 0 0 1px 1px $grey-dark;
    box-shadow: 0 0 1px 1px $grey-dark;
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $grey-dark;
    -webkit-box-shadow: 0 0 1px 1px $grey-dark;
    box-shadow: 0 0 1px 1px $grey-dark;
}
