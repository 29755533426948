@import '../../scss/imports.scss';
.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    border-radius: 2px;
    padding: 12px;
    height: 48px;
    color: $zl-deep-blue-light;
    box-shadow: none;
    -webkit-box-shadow: none;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

    &:active {
        color: $true-white;
    }

    &.tall {
        height: 54px;
    }

    &[disabled] {
        background-color: $grey-lightest;

        & > span {
            color: $grey-light;
        }
    }

    & > .anticon {
        line-height: unset;
    }

    & .zl-icon {
        &.ant-spin-dot {
            svg {
                width: 32px;
                height: 32px;
            }
        }
    }
}

a.ant-btn,
.ant-btn-link {
    color: $zl-light-blue;
    padding: 0px 4px;
    height: auto;
    line-height: 16px;

    & > span {
        color: $zl-light-blue;
    }

    &:focus {
        color: $zl-light-blue;
    }
    &:visited {
        color: $zl-light-blue;
    }
    &:active {
        color: $zl-deep-blue;
    }

    &:hover {
        & > span {
            color: $zl-deep-blue;
        }
    }

    @media (hover: hover) {
        &:hover {
            & > span {
                color: $zl-deep-blue;
            }
        }
    }
}

.ant-btn-primary {
    background-color: $zl-light-blue;
    border-color: $zl-light-blue;

    & span {
        color: $true-white;
    }

    & svg path:not([fill='none']) {
        fill: $true-white;
    }

    &.selected,
    &:active,
    &:focus {
        background-color: $zl-light-blue;
        border-color: $zl-light-blue;
    }

    @media (hover: hover) {
        &:hover {
            background-color: $zl-light-blue;
            border-color: $zl-light-blue;
        }
    }
}

.ant-btn-default {
    border-color: $grey-lightest;

    &:not([disabled]) {
        @media (hover: hover) {
            &:hover {
                background-color: $zl-deep-blue-light;
                border-color: $zl-deep-blue-light;
                color: $true-white;

                & span {
                    color: $true-white;
                }
            }
        }

        &.selected,
        &:active,
        &:focus {
            background-color: $zl-deep-blue-light;
            border: none;
            color: $true-white;

            & span {
                color: $true-white;
            }
        }
    }
}

.ant-typography > .ant-btn-link {
    margin: 0;
    padding: 0;
}

// .ant-btn > .anticon + span,
// .ant-btn > span + .anticon {
//     margin-left: 6px;
// }
