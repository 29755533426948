//imports
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~antd/dist/antd.css';
@import './src/scss/imports.scss';

@font-face {
    font-family: 'SuperGround';
    src: local('SuperGround'), url('./fonts/SuperGround.woff2') format('woff2'),
        url('./fonts/SuperGround.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'SweetSansPro-Bold';
    src: local('SweetSansPro-Bold'), url('./fonts/SweetSansPro-Bold.woff2') format('woff2'),
        url('./fonts/SweetSansPro-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'SweetSansPro';
    src: local('SweetSansPro-Regular'), url('./fonts/SweetSansPro-Regular.woff2') format('woff2'),
        url('./fonts/SweetSansPro-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'SweetSansPro-Medium';
    src: local('SweetSansPro-Medium'), url('./fonts/SweetSansPro-Medium.woff2') format('woff2'),
        url('./fonts/SweetSansPro-Medium.woff') format('woff');
    font-display: swap;
}

body {
    font-family: 'Sweet Sans Pro', 'montserrat', sans-serif !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5715;
    letter-spacing: 0.2px !important;
    color: $zl-deep-blue !important;
    background-color: #f5f5f5 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section.ant-layout,
main.ant-layout-main,
footer.ant-layout-footer {
    background-color: $background;
    padding-bottom: 128px;

    @include media('<lg') {
        padding-bottom: 0;
    }
}

.ant-layout-header {
    height: unset;
    line-height: unset;
}

.content-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.nav-progress-container {
    display: flex;
    justify-content: center;
    max-width: unset;
    background-color: $true-white;
    border-bottom: 1px solid $white-border;
}
