@import '../../scss/imports.scss';

.zl-location {
    padding: 0;

    & > .ant-typography {
        white-space: normal;
        color: $zl-light-blue;
    }
}
