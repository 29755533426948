@import '../../scss/imports.scss';

.zl-number-picker-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-left: -4px;
    margin-right: -4px;

    & .zl-number-button {
        margin: 8px 4px;
        width: calc((100% - 36px) / 4);

        @include media('>=sm') {
            width: calc((100% - 64px) / 8);
        }
    }
}
