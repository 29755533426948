@import '../../scss/imports.scss';

.zl-private-event-type-dropdown {
    width: 100%;
    max-width: 600px;
    border-radius: 2px;

    & .ant-select-selector {
        height: 56px !important;
        padding: 8px 16px;
    }

    & .ant-select-selection-item {
        line-height: 56px !important;
        color: $zl-deep-blue-lighter;
    }

    & .ant-select-selection-placeholder {
        opacity: 1;
        color: $grey-dark;
        line-height: 56px !important;
    }

    & .ant-select-arrow {
        color: $grey-dark;
    }

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $zl-deep-blue-lighter;
    }

    & .ant-select-selection-search-input {
        height: 56px !important;
        font-size: 16px;
        color: $zl-deep-blue-lighter;
    }

    &:focus-within {
        border-color: $grey-dark;
        box-shadow: 0 0 1px 1px $grey-dark;
    }
}

.ant-select-selection__placeholder {
    color: $grey-dark;
}

.zl-private-event-type-dropdown-menu {
    max-height: 65vh;

    .ant-select-item.ant-select-item-group {
        color: $zl-deep-blue-light;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.2px;
        font-weight: 600;
        padding: 8px 16px;
        text-transform: uppercase;
    }

    .zl-private-event-type-option {
        height: 49px;
        color: $grey-dark;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.2px;
        padding: 16px 24px;

        &:hover {
            color: $zl-deep-blue-lighter;
            background-color: $background;
        }

        &.ant-select-item-option-selected {
            color: $true-white;
            font-weight: normal;
            background-color: $grey-dark;
        }
    }
}

.ant-row-rtl {
    & .ant-select-arrow {
        left: 11px;
        right: unset;
    }

    & .ant-select-selection__rendered {
        margin-left: 24px;
    }

    & .ant-select-selection__placeholder {
        text-align: right;
    }

    & .ant-select-selection-selected-value {
        float: right;
    }
}
