@import '../../scss/imports.scss';

.zl-calendar {
    width: 100%;
    max-width: 600px;

    .ant-picker-date-panel {
        & > .ant-picker-body {
            padding: 0;
        }

        .ant-picker-content {
            thead {
                tr {
                    font-size: 12px;
                    text-transform: uppercase;
                    text-align: center;
                    th {
                        padding: 8px 0;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.ant-picker-cell {
                            opacity: 0.2;
                            height: 64px;

                            &.ant-picker-cell-in-view {
                                opacity: 1;
                            }

                            & .ant-calendar-date {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                height: 56px;
                                margin: 0 auto;
                                width: 90%;
                                text-align: center;
                                line-height: 24px;

                                &.today {
                                    &:not(.selected) {
                                        border-radius: 2px;
                                        border: 1px solid $base-borders;
                                    }
                                }

                                &.statused {
                                    padding-top: 16px;
                                }

                                &.selected {
                                    border-radius: 2px;
                                    background-color: $zl-deep-blue-lighter;

                                    & .date-value {
                                        color: $true-white;
                                    }

                                    & .status {
                                        color: $true-white;
                                    }
                                }

                                &.full {
                                    span {
                                        color: $error;
                                    }

                                    span.date-value {
                                        text-decoration: line-through;
                                    }
                                }

                                &.disabled:not(.full) {
                                    color: $grey-light;
                                    background: repeating-linear-gradient(
                                        -45deg,
                                        rgba(111, 111, 110, 0.1),
                                        rgba(111, 111, 110, 0.1) 2px,
                                        rgba(200, 200, 200, 0.1) 2px,
                                        rgba(200, 200, 200, 0.1) 7px
                                    );

                                    span {
                                        color: $grey-light;

                                        &.past-date {
                                            text-decoration: line-through;
                                        }
                                    }
                                }
                            }

                            span.date-value {
                                font-size: 18px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.33;
                                letter-spacing: 0.02px;
                                color: $zl-deep-blue-lighter;
                            }

                            span.status {
                                font-size: 9px;
                                font-weight: 500;
                                text-transform: uppercase;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.78;
                                letter-spacing: 0.1px;
                                color: $grey-dark;

                                &.today {
                                    font-weight: bold;
                                    color: $zl-deep-blue-lighter;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .zl-calendar-header {
        display: flex;
        padding: 8px 0;

        @include media('>=sm') {
            padding: 8px 16px;
            justify-content: center;
        }

        .ant-btn {
            &.zl-month-button {
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-radius: 0;
                height: 36px;
                padding: 8px;
                text-transform: uppercase;
                color: $zl-deep-blue-lighter;
                border-bottom: 2px solid $true-white;
                font-weight: 500;
                font-size: 14px;
                min-width: 96px;

                &.selected {
                    color: $zl-light-blue;
                    border-bottom: 2px solid $zl-light-blue;
                }
            }
        }
    }
}
