// --------------
// Text Changes
// --------------

.heading-indent {
    margin: 32px;
    margin-left: 8px;
    @include media('>=md') {
        margin-left: 126px;
    }
}

.text-center {
    text-align: center;
}

.text-small {
    font-size: 85%;
}

.text-right {
    text-align: right;
}

// -------------
// Icons
// -------------
.zl-icon {
    position: relative;
    object-fit: contain;

    & > svg {
        width: 24px;
        height: 24px;
    }

    &.green {
        & svg path[fill='#38BCEE'] {
            fill: $success;
        }

        & svg path[fill='#38bcee'] {
            fill: $success;
        }
    }

    &.white {
        & svg path[fill='#38BCEE'],
        & svg path[fill='#38bcee'] {
            fill: $true-white;
        }

        & svg path[fill='#072F3D'],
        & svg path[fill='#072f3d'] {
            fill: $true-white;
        }
    }

    &.red {
        & svg path[fill='#38BCEE'],
        & svg path[fill='#38bcee'] {
            fill: $error;
        }

        & svg path[fill='#072F3D'],
        & svg path[fill='#072f3d'] {
            fill: $error;
        }
    }

    &.float {
        float: right;
    }
}

.ant-row-rtl {
    .zl-icon {
        transform: scaleX(-1);
    }
}
