@import '../../scss/imports';

.zl-loading-spinner-container {
    width: 100%;

    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    & > .ant-spin.ant-spin-lg {
        & .ant-spin-dot {
            font-size: 54px;

            & svg path[fill='#FFF'] {
                fill: $zl-light-blue;
            }

            & svg path[fill='#fff'] {
                fill: $zl-light-blue;
            }
        }
    }
}
