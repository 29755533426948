@import '../../scss/imports.scss';

.games-modal-container {
    @include media('>=md') {
        max-width: 600px;
    }

    .ant-modal-close {
        display: block !important;
    }

    .ant-btn.close {
    }

    .ant-modal-confirm-btns {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
}
