@import '../../scss/imports.scss';

.zl-time-picker-container {
    margin-left: -3px;
    margin-right: -3px;

    @include media('>=md') {
        margin-left: -6px;
        margin-right: -6px;
    }
}

.ant-btn.zl-time-button {
    margin: 8px 3px 8px 3px;
    width: calc((100% - 48px) / 4);
    height: 56px;
    border-radius: 2px;
    line-height: 1.4;
    min-width: 79px;
    flex-wrap: wrap;

    @include media('>=sm') {
        width: calc((100% - 48px) / 6);
    }

    @include media('>=md') {
        margin-left: 6px;
        margin-right: 6px;
        width: calc((100% - 48px) / 7);
        max-width: 83px;
    }

    & span {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: $zl-deep-blue-lighter;

        & > span.time-suffix {
            color: $zl-text-grey;
            margin: 0 3px;
            font-size: 9px;
        }

        @include media('>=md') {
            font-size: 18px;
        }
    }

    &[disabled] {
        border: none;
        background: $true-white;
        opacity: 1;
        pointer-events: none;

        & span {
            color: $error;

            & > span.time-suffix {
                color: $error;
            }
        }
    }

    &.sold-out {
        & span {
            &.time {
                text-decoration: line-through;
            }
        }
    }

    & > span.status {
        display: block;
        font-size: 9px;
        text-transform: uppercase;
    }

    &.selected,
    &:hover {
        & > span.time {
            color: $true-white;

            & > span.time-suffix {
                color: $true-white;
            }
        }
    }
}
